.marqueecolor{
    background-color: rgb(4, 94, 122);
}





* {box-sizing: border-box}

/* Container needed to position the overlay. Adjust the width as needed */
.hoverimage1 {
  position: relative;

}

/* Make the image to responsive */
.image2 {
  display: block;
  width: 100%;
  height: auto;
}

/* The overlay effect - lays on top of the container and over the image */
.overlay1 {
  position: absolute;
  bottom: 0;
  background: rgb(20, 20, 21);
  background: rgba(24, 25, 25, 0.5); /* Black see-through */
  color: #f1f1f1;
  width: 100%;
  transition: .5s ease;
  opacity:0;
  color: white;
  font-size: 20px;
  padding: 20px;
  text-align: center;
  border-radius: 0px;
}

/* When you mouse over the container, fade in the overlay title */
.hoverimage1:hover .overlay1 {
  opacity: 2;
}




.blink { 
    animation: blink-animation 1s infinite; 
  } 
    
  @keyframes blink-animation { 
    0% { 
      opacity: 1; 
    } 
    50% { 
      opacity: 0; 
    } 
    100% { 
      opacity: 1; 
    } 
  }

  .color{
    background-color: #f2f8fd;
  }

  .researchprogram
  {
background-color: #fbeded;
  }

  .events{
    background-color: #f3f8f9;
  }

  .buttongrup button{
    cursor: pointer;
    width: 200px;
    height: 40px;
    border-top-left-radius: 25px;
    border-bottom-right-radius: 25px;
    border: none;
    text-decoration: rgb(32, 23, 23);
    color: rgb(254, 254, 254);
    background-color: #147309;
    box-shadow: 0 4px 10px rgba(50, 27, 27, 0.1);


  }



  
  .marquee{
    scroll-behavior: smooth;
  }
  

  .directors{
    background-color: #3b697d; /* Green */
    border: none;
    color: white;
    padding: 8px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 9px;
  }

  .buttonclose{
    background-color: #15729a; 
    border: none;
    color: white;
    padding: 6px 14px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 4px;
  }