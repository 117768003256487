.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


a {
  text-decoration: none !important;
}

.container {
  font-family: "Encode Sans Expanded", sans-serif;
  font-weight: 200;
  font-style: normal;
}


.justifytexts{
  text-align: justify;
  text-justify: inter-word;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 6px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

.colorback{
  background-color: #8d99b2
}

.radius50 img{
  border-radius: 12px;
}


/* .facultycolor{

} */


.buttongrup1 button{
  cursor: pointer;
  width: 250px;
  height: 80px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  border: none;
  text-decoration: rgb(32, 23, 23);
  color: rgb(254, 254, 254);
  background-color: #147309;
  box-shadow: 0 4px 10px rgba(50, 27, 27, 0.1);


}

.texts{
  text-align: justify;
  text-justify: inter-word;
}



.btn-grad15 {
  background-image: linear-gradient(to right, #76c2c2 0%, #14767f  51%, #2b5876  100%);
  margin: 0px;
  padding: 9px 29px;
  font-size: 14px;
  text-align: center;
  /* text-transform: uppercase; */
  transition: 0.5s;
  background-size: 200% auto;
  color: white;            
  box-shadow: 0 0 20px #eee;
  border-radius: 4px;
  display: block;
}

.btn-grad15:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}


.sericulturalberhamporemurshidabad{
  background-color: #65a9b0;
}

.backgroundc{
  background-color: #f0ecd5;
}





.btn-grad14 {
  background-image: linear-gradient(to right, #064848 0%, #960404  51%, #2b5876  100%);
  margin: 0px;
  padding: 9px 29px;
  font-size: 14px;
  text-align: center;
  /* text-transform: uppercase; */
  transition: 0.5s;
  background-size: 200% auto;
  color: white;            
  box-shadow: 0 0 20px #eee;
  border-radius: 18px;
  display: block;
}

.btn-grad14:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}


.colorbacks{
  background-color: #e3f3f3;
}

.colorne{
  background-color:#127bb4;
  padding: 14px;
}

.sericulturalberhampore h4{
  font-family: "DM Serif Text", serif;
  font-weight: 400;
  font-style: normal;
}

.centralsilkberhamore{
  background-color: #15326d; /* Green */
  border: none;
  color: white;
  padding: 5px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  border-radius: 10px;
}

.minites{
  background-color: #3366CC; /* Green */
  border: none;
  color: white;
  padding: 8px 30px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  border-radius: 0px;
}

.mandate{
  background-color: #740a2b; /* Green */
  border: none;
  color: white;
  padding: 8px 30px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  border-radius: 0px;
}


.buttonview{
  color: #00008B;
}


.image-container img {

  object-fit: cover; /* Ensures the image fills the area without distortion */

}


.radius img{
  border-radius: 14px;
}